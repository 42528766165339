<template>
  <div class="menus">
    <div class="menuContainer">
      <div class="logoBox">
        <img src="@/assets/image/logo.png" alt="" class="logoSty" />
      </div>
      <div class="menuBox">
        <!-- <div
          class="menuBtn"
          v-for="item in menus"
          :key="item.value"
          @click="changeMenu(item)"
          :class="currentMenu == item.value ? 'isActive' : ''"
        >
          <div class="menuTitle">{{ item.title }}</div>
          <div
            v-if="item.children && currentMenu == item.value"
            class="childrenBox"
          >
            <div
              class="childrenMnuBtn"
              v-for="menuChidren in item.children"
              :key="menuChidren.value"
              @click="changeMenuChild(menuChidren)"
              :class="
                currentMenuChild == menuChidren.value && menuChidren.isChildren
                  ? 'isActive'
                  : ''
              "
            >
              {{ menuChidren.title }}
            </div>
          </div>
        </div> -->
        <el-menu
            :default-active="activeMenu"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            router
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/business">业务领域</el-menu-item>

          <el-submenu index="3" popper-append-to-body>
            <template slot="title">行业与解决方案</template>
            <el-menu-item index="/product">智慧水利</el-menu-item>
            <el-menu-item index="/electricity">智慧电力</el-menu-item>
<!--            <el-menu-item index="/mappingService">测绘服务</el-menu-item>-->
            <el-menu-item index="/environmental">环保应用</el-menu-item>
            <el-menu-item index="/education">教育培训</el-menu-item>
<!--            <el-menu-item index="/industry">无人机行业应用</el-menu-item>-->
<!--            <el-menu-item index="/hardware">无人机系统集成</el-menu-item>-->
          </el-submenu>
          <el-menu-item index="/aboutUs">关于我们</el-menu-item>
        </el-menu>
        <span> | </span>
        <div>
          <div class="teleImgBox">
            <img src="@/assets/image/home_top_icon_tel.png" alt="" class="teleImg">
          </div>
	        025 8663 5616
        </div>
        <div class="clearFloat"></div>
      </div>
      <div class="clearFloat"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    activeMenu() {
      const path = this.$route.path;
      let activePath = path;
      if (path !== undefined && path.lastIndexOf("/") > 0) {
        const tmpPath = path.substring(1, path.length);
        activePath = "/" + tmpPath.substring(0, tmpPath.indexOf("/"));
      } else if (!this.$route.children) {
        activePath = path;
      }
      console.log(activePath);
      return activePath;
    },
  },
  watch:{
    '$route':function(to,from){
      document.body.scrollTop = 0;
     document.documentElement.scrollTop = 0;
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    changeMenu(item) {
      item.flag = !item.flag;
      this.currentMenu = item.value;
      console.log(item.flag);
      console.log(this.currentMenu);
      this.$router.push(item.url);
    },
    changeMenuChild(item) {
      item.flag = !item.flag;
      this.currentMenuChild = item.value;
      console.log(item);
      this.$router.push(item.url);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.menus {
  width: 100%;
  height: 7rem;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  color: #fff;
  .isActive {
    background: rgba(0, 156, 255, 1);
  }
  .menuContainer {
    width: 120rem;
    margin: auto;
    height: 100%;
    line-height: 7rem;
    .logoBox {
      height: 5.4rem;
      margin-top: 0.8rem;
      float: left;
      .logoSty {
        //width: 100%;
        height: 100%;
      }
    }
    .menuBox {
      width: 50%;
      height: 100%;
      float: right;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .menuBtn {
        height: 100%;
        .menuTitle {
          padding: 0 2.7rem;
        }
        .childrenMnuBtn {
          text-align: center;
          height: 4rem;
          line-height: 4rem;
        }
      }
      .childrenBox {
        background: rgba(0, 0, 0, 0.7);
        width: 100%;
        .childrenMenuBtn {
          height: 4.1rem;
          text-align: center;
          line-height: 4.1rem;
        }
      }
    }
  }
}
.teleImgBox {
  width: 2.4rem;
  height: 100%;
  float: left;
  margin: 0 1rem;
  .teleImg {
    width: 100%;
    height: 2.4rem;
    margin-top: 2.5rem;
    margin-right: 1rem;
  }
}

.el-menu-demo {
  background-color: rgba(0, 0, 0, 0);
}
::v-deep .el-menu.el-menu--horizontal {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
}
::v-deep .el-menu--horizontal {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
}
::v-deep .el-menu-item:hover {
  color: #fff !important;
  outline: 0 !important;
  background-color: #009cff !important;
}
::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #fff;
  border: none;
}

::v-deep .el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background-color: rgba(0, 0, 0, 0) !important;
}
::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  background-color: rgba(0, 0, 0, 0) !important;
  height: 100%;
  line-height: 6.6rem;
  border: none;
  color: #fff;
}
::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  background-color: #009cff !important;
  height: 7rem;
  color: #fff !important;
}
::v-deep .el-menu--horizontal>.el-submenu:focus .el-submenu__title {
  color: #fff !important;
}
::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title:hover {
  border-bottom: none !important;
}
::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  border-bottom: 0.4rem solid #009cff !important;
  height: 7rem;
}
::v-deep .el-submenu__title:hover {
  color: #fff !important;
  background-color: #009cff !important;
}
::v-deep .el-menu--horizontal > .el-menu-item:hover {
  border-bottom: 0.4rem solid #009cff !important;
}
::v-deep .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
  border-bottom: none;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  background-color: #009cff !important;
  color: #fff;
}
::v-deep .el-menu--horizontal > .el-menu-item {
  background-color: transparent !important;
  border: none;
  height: 100%;
  line-height: 70px;
  color: #fff;
}
::v-deep .el-menu--popup {
  background-color: rgba(0, 0, 0, 0) !important;
}
::v-deep .el-menu--popup-bottom-start {
  background-color: rgba(0, 0, 0, 0) !important;
}
::v-deep .el-menu {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>
