<template>
  <div class="footerBox animate__animated animate__fadeInUp">
    Copyright © 2022-2023南京木牛数能信息技术有限公司版权所有   ICP证：
	  <span class="linkSty"> <a href="https://beian.miit.gov.cn">苏ICP备2022034626号</a></span>

  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">
  .footerBox {
    width: 100%;
    min-width: 120rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #444444;
    height: 7.4rem;
    line-height: 7.4rem;
    text-align: center;
    color: #fff;
    font-size: 1.6rem;
  }
  .linkSty {
	  color: #0f8abd;
  }
</style>
