import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import "swiper/css/swiper.css"; //引入swiper样式
import 'wowjs/css/libs/animate.css'


import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/index.scss' // global css
import 'animate.css';
import "swiper/css/swiper.css";
import "@/assets/styles/animateDelay.scss"


Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.directive('animate', {
  inserted: function (el, binding) {
    // 聚焦元素
    binding.addClass = () => {
      const { top } = el.getBoundingClientRect()
      const h = document.documentElement.clientHeight || document.body.clientHeight
      if (top < h) {
        if(el.className.indexOf(binding.value) == -1 ){
          // 初次还未绑定过，则新增类名(注意：下面单引号中间是一个空格！！！)
          el.className = binding.value+' '+el.className
        }
        if (binding.addClass) {
          window.removeEventListener('scroll', binding.addClass)
        }
      }
    }
    window.addEventListener('scroll', binding.addClass,true)
    binding.addClass()
  },
  unbind: function (el, binding) {
    if (binding.addClass) {
      window.removeEventListener('scroll', binding.addClass)
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
