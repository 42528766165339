<template>
  <div class="home">
    <NavBar></NavBar>
    <HomeNew></HomeNew>
    <BackTop></BackTop>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/navBar";
import BackTop from "@/components/backTop";
import Footer from "@/components/footer";
import HomeNew from "@/views/home/homeNew";

export default {
  name: 'Home',
  components:{ NavBar, HomeNew, Footer, BackTop },
  data() {
    return {

    }
  },

  methods:{
  }
}
</script>
<style scoped lang="scss">
.home{
  width: 100%;
  position: relative;

}
</style>
