import Vue from 'vue'
import Router from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/businessDomain/business.vue')
  },
  {
    path: '/businessDetail',
    name: 'businessDetail',
    component: () => import('../views/businessDomain/components/businessDetail.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product.vue')
  },
  {
    path: '/electricity',
    name: 'electricity',
    component: () => import('@/views/product/electricity.vue')
  },
  {
    path: '/mappingService',
    name: 'mappingService',
    component: () => import('@/views/product/mappingService.vue')
  },
  {
    path: '/environmental',
    name: 'environmental',
    component: () => import('@/views/product/environmental.vue')
  },
  {
    path: '/industry',
    name: 'industry',
    component: () => import('@/views/product/industry.vue')
  },
  {
    path: '/hardware',
    name: 'hardware',
    component: () => import('@/views/product/hardware.vue')
  },
  {
    path: '/education',
    name: 'education',
    component: () => import('@/views/product/education.vue')
  },
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})
