<script src="../businessDomain/businessData.js"></script>
<template>
  <div class="homeNew">
    <div class="bannerBox">
      <div class="bannerCourser">
        <el-carousel ref="carousel" :interval="3000" arrow="hover" :height="bannerHeight+'px'" indicator-position="none" @change="change">
          <el-carousel-item v-for="(banItem, banIndex) in bannerList" :key="banIndex">
            <img :src="banItem.img" alt="" class="imgStylebanner">
            <div class="banContent animate__animated animate__fadeInRight">
              <div class="banTitle animate__animated animate__fadeInRight">{{ banItem.title }}</div>
              <div class="subTitle animate__animated animate__fadeInRight">{{ banItem.subTitle }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="changeBanner">
        <div class="changeArrow">
          <span class="leftArrow">
            <img src="@/assets/image/leftBtn.png" alt="" class="leftBtnSty" @click="leftBtn">
          </span>
          <span class="leftArrow">
            <img src="@/assets/image/rightBtn.png" alt="" class="leftBtnSty" @click="rightBtn">
          </span>
        </div>
        <div class="discribe">
          <ul>
            <li
              v-for="(desItem, desIndex) in bannerList"
              :key="desIndex"
              data-wow-duration="1s"
              :id="'aniDes' + desIndex"
              class="animate__animated animate__fadeInUp"
              v-if="movebannerIndex == desIndex"
              >
              <span>{{ desItem.desc }}</span>
            </li>
          </ul>
        </div>
        <div class="changeDot">
          <div
              v-for="(banItem, banIndex) in bannerList"
              :class="movebannerIndex == banIndex ? 'activeDotBox' : 'dotBox'"
              :key="banIndex"
              @click="changeIndex(banIndex)"
          ></div>
        </div>
        <div class="clearFloat"></div>
      </div>
    </div>
    <!-- 业务领域 -->
    <div class="bussinessArea">
      <div class="bussinessInside">
        <div class="bussinessTitle wow animate__fadeInUp">
          业务领域
          <span class="bussinessSubTitle">Business area</span>
        </div>
        <div class="bussinessContainer">
          <div
              class="bussinessOne wow animate__fadeInUp"
              v-for="(busItem, busIndex) in businessAreaList"
              :key="busIndex"
              :data-wow-duration="busItem.sty">
            <div class="bussiImgBox">
              <img :src=" busItem.img" alt="" class="imgStyle">
            </div>
            <div class="busOneDesc wow animate__fadeInUp">
              <div class="bussunessTitleOne">{{busItem.title}}</div>
              <div class="businessDes">{{busItem.desc}}</div>
            </div>
            <div class="deBtn" @click="toShow(busItem)">
              <el-button class="detailsButton">了解详情</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 行业与解决方案 -->
    <div class="productsSolutions">
      <div class="bussinessInside">
        <div class="bussinessTitle wow animate__fadeInUp">
          行业与解决方案
          <span class="bussinessSubTitle">Product and solutions</span>
        </div>
        <div class="productsContainer">
          <div class="leftMenu wow animate__fadeInLeft">
            <ul>
              <li
                  v-for="(proItem,proIndex) in productLeftMenu"
                  @click="showTitle(proItem,proIndex)"
                  :key="proIndex"
                  :class="productVlue === proIndex ? 'hoverli' : 'hoverLi'"
                  style="animation-name: fadeInUp">
                {{proItem.title}}
              </li>
            </ul>
          </div>
          <div class="rightContent wow animate__fadeInRight" id="proRight">
            <div class="rightTitle" id="proTitle">{{productTitle}}</div>
            <div class="desc" id="proDesc">{{ productDesc }}</div>
            <div class="rightImg" id="proImg">
              <img :src="productImg" alt="" class="imgStyle">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="aboutUsBox">
      <div class="bussinessInside">
        <div class="bussinessTitle">
          关于我们
          <span class="bussinessSubTitle">about us</span>
        </div>
      </div>
      <div class="concat">
        <div class="container">
          <!-- 公司介绍 -->
          <div class="profile wow animate__fadeInUp">
            <div class="leftImg">
              <img src="@/assets/image/home_nr_pic_jieshao.png" alt="" class="imgStyle">
            </div>
            <div class="rightDesc">
<!--              <div class="squireBox"></div>-->
              <div class="proTitle">南京木牛数能信息技术有限公司</div>
              <div class="proSubTitle">Company Profile</div>
              <div class="proRightDesc">
                南京木牛数能信息技术有限公司成立于2022年，公司致力于将物联网技术、数字孪生技术、AI技术和3S技术（RS、GIS、GPS）与无人机行业应用场景相融合，打造空天地一体化全面感知、全面物联的智慧监控监测体系的高科技公司。木牛公司总部位于南京软件谷科创城，在无锡惠山设有无锡子公司，在南京、连云港、无锡、上海、合肥等地设有服务机构和培训中心，公司现有各类技术人员百余人。
                木牛依托南京大学、南京航空航天大学、河海大学及江苏省电子学会等单位长期持续的进行研发投入，目前拥有数十项项专利、软著等知识产权。木牛具有民航总局《通用航空经营许可证》、民航总局AOPA无人机驾驶员培训机构资质、测绘航空摄影专业乙级资质、CMMI3级证书，同时木牛也获得了软件产品和软件企业双软认证；
                木牛是无人机技术在水利、环保、城市管理等行业应用的先行者。木牛的空天地一体化监测监控平台在江苏、上海、河南、浙江、安徽多地生态环境部门、水利部门、城市管理部门获得成功应用。作为大疆行业代理和生态合作伙伴，木牛在产品交付和软件集成、面向行业的内外业技术服务上案例众多能力突出；
                木牛是数字孪生技术的领先者，在水利数字孪生领域承担了多个水利部数字孪生先行先试项目。应用案例和场景覆盖水利（大型水利枢纽、流域、长距离输水工程、城区、中小型水库、中小型水利设施）、交通、智慧城市、安防和城市管理等众多领域。
              </div>
              <div class="deBtn2" @click="toAboutDetail">
                <el-button class="detailsButton">了解详情</el-button>
              </div>
            </div>
            <div class="clearFloat"></div>
          </div>
          <!-- 业务联系人 -->
          <div class="businessContact">
            <div class="containerTitle wow animate__fadeInUp">
              <i class="el-icon-arrow-right concatIcon"></i>
              业务联系人
              <i class="el-icon-arrow-left concatIcon"></i>
            </div>
            <div class="concatList">
              <div class="concatOne wow animate__fadeInUp" data-wow-duration="0.3s">
                <div class="concatTrans">
	                <i class="el-icon-s-custom oneIcon"></i>
	                {{ concatList[0].name }}
                </div>
                <div class="transition"></div>
                <div style="margin-top: 10px">
                  <i class="el-icon-phone oneIcon"></i>
                  {{ concatList[0].phone }}
                </div>
              </div>

              <div class="concatOne wow animate__fadeInUp" data-wow-duration="0.6s">
                <div class="concatTrans">
	                <i class="el-icon-s-custom oneIcon"></i>
	                {{ concatList[1].name }}
                </div>
                <div class="transition"></div>
                <div style="margin-top: 10px">
                  <i class="el-icon-phone oneIcon"></i>
                  {{ concatList[1].phone }}
                </div>
              </div>
              <div class="concatOne wow animate__fadeInUp" data-wow-duration="0.9s">
                <div class="concatTrans">
	                <i class="el-icon-s-custom oneIcon"></i>
	                {{ concatList[2].name }}
                </div>
                <div class="transition"></div>
                <div style="margin-top: 10px">
                  <i class="el-icon-phone oneIcon"></i>
                  {{ concatList[2].phone }}
                </div>
              </div>
              <div class="concatOne wow animate__fadeInUp" data-wow-duration="1.2s">
                <div class="concatTrans">
	                <i class="el-icon-s-custom oneIcon"></i>
	                {{ concatList[3].name }}
                </div>
                <div class="transition"></div>
                <div style="margin-top: 10px">
                  <i class="el-icon-phone oneIcon"></i>
                  {{ concatList[3].phone }}
                </div>
              </div>
              <div class="concatOne wow animate__fadeInUp" data-wow-duration="1.5s">
                <div class="concatTrans">
	                <i class="el-icon-s-custom oneIcon"></i>
	                {{ concatList[4].name }}
                </div>
                <div class="transition"></div>
                <div style="margin-top: 10px">
                  <i class="el-icon-phone oneIcon"></i>
                  {{ concatList[4].phone }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 资质 -->
      <div class="qualification">
        <div class="quliContent">
          <div class="qualificationTitle">
            <i class="el-icon-arrow-right qualiIcon"></i>
            荣誉与资质
            <i class="el-icon-arrow-left qualiIcon"></i>
          </div>
          <div class="qulificationList1">
            <div class="qulificationOne" v-animate="'animate__animated animate__fadeInUp'" v-for="(item,index1) in quliPicture1" :key="index1" :class="' delay' + (index1 + 1)">
              <!--<div class="qulificationOne wow animate__fadeInUp" v-for="(item,index1) in quliPicture1" :key="index1" :data-wow-duration="(index1 + 1) + 's'">-->
              <img :src="item.img" alt="" class="imgStyle">
            </div>
          </div>
          <div class="qulificationList2">
            <div class="qulificationOne" v-animate="'animate__animated animate__fadeInUp'" v-for="(item,index2) in quliPicture" :key="index2" :class="' delay' + (index2 + 1)">
              <!--<div class="qulificationOne wow animate__fadeInUp" v-for="(item,index2) in quliPicture" :key="index2"  :data-wow-duration="(index2 + 7) + 's'">-->
              <img :src="item.img" alt="" class="imgStyle">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="contactBox wow queue-bottom">
      <div class="bussinessInside contactContainer">
        <div class="bussinessTitle">
          联系我们
          <span class="bussinessSubTitle">contact us</span>
        </div>
        <div class="bussinessContainer wow animate__fadeInUp" data-wow-duration="0.5s">
          <div class="leftPhone">
            <div class="phoneTitle">南京木牛数能信息技术有限公司</div>
            <div class="phoneDetail">
              <div class="leftAdd">
                <div>地址: 南京市雨花台区软件谷科创城A2南9层</div>
                <div>邮编: 210000</div>
                <div>电话: 025 8663 5616</div>
              </div>
              <div class="rightTele">
                <div>手机: 18114467296</div>
                <div>传真: 025 8967 4317</div>
                <div>邮箱: muniu@automaticox.com</div>
              </div>
            </div>
          </div>
          <div class="rightMap">
            <div id="mapContainer" class="mapContainer"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import WOW from 'wowjs';
export default {
  name:'HomeNew',
  data() {
    return {
      bannerHeight: 0,
      screenWidth: 0,
      movebannerIndex: 0,
      activeItem:0,
      bannerList: [
        {
          img:require('@/assets/image/banner01.png'),
          desc:'一个平台，四预赋能，多重增效，双向引领。',
          title:'智慧水利',
          // subTitle:'防汛监测、水库管理、水资源管理、河湖管理。',
	        subTitle:'一个平台，四预赋能，多重增效，双向引领。',
        },
        {
          img:require('@/assets/image/banner02.png'),
          desc:'智能高效，广泛互联，清洁低碳。',
          title:'智慧电力',
          subTitle:'智能高效，广泛互联，清洁低碳。',
        },
        {
	        img:require('@/assets/image/banner03.png'),
	        desc:'正射影像、倾斜摄影、激光点云数据采集、GIS 数据加工。',
	        title:'测绘服务',
	        subTitle:'正射影像、倾斜摄影、激光点云数据采集、GIS 数据加工。',
        },
        {
          img:require('@/assets/image/banner04.png'),
          desc:'基于无人机、IOT、GIS、AI、大数据等技术针对水、气、土多维度监测管理。',
          title:'环保应用',
          subTitle:'基于无人机、IOT、GIS、AI、大数据等技术针对水、气、土多维度监测管理。',
        },
        // {
	    //     img:require('@/assets/image/banner05.png'),
	    //     desc:'实时自动生成2D/3D污染浓度分布图结合无人机空中高清影像，实现“现场可视化”“大区域”、“精准”、“高效快速” 及“三维立体”地监测分析。',
	    //     title:'无人机行业应用',
	    //     subTitle:'实时自动生成2D/3D污染浓度分布图结合无人机空中高清影像，实现“现场可视化”“大区域”、“精准”、“高效快速” 及“三维立体”地监测分析。',
        // },
        {
	        img:require('@/assets/image/banner08.jpg'),
	        desc:'基于无人机及机巢集成多种行业载荷，实现多行业领域空地一体化、无人化、智能化监管能力，赋能生产力，解放人工、提升效率。',
	        title:'无人机行业应用',
	        subTitle:'基于无人机及机巢集成多种行业载荷，实现多行业领域空地一体化、无人化、智能化监管能力，赋能生产力，解放人工、提升效率。',
        },
      ],
      bannerMessage:{
        bannerDesc: '',
        banCurrentIndex:0,
        direction:'right',
      },
      businessAreaList: [
        {
          value:'1',
          img:require('@/assets/image/business/business11.png'),
          desc:'数字孪生是充分利用物理模型、传感器数据更新、运行历史等数据，集成多学科、多物理量、多尺度、多概率的仿真过程，在虚拟空间中完成对现室世界的映射...',
          title:'数字孪生应用开发',
          sty:'0.3s',
          pageIndex:'1',
        },
        {
          value:'2',
          img:require('@/assets/image/business/business22.png'),
          // desc:'将数据库下发到终端智能设备，由基于开发业务终端软件，利用智能识别算法从无人机采集视频中提取出车辆信息，实时检索，快速识别违法车辆，进行锁定跟踪取证...',
	        desc:'基于无人机及机巢集成多种行业载荷，实现多行业领域空地一体化、无人化、智能化监管能力，赋能生产力，解放人工、提升效率。',
          title:'无人机行业应用',
          sty:'1.2s',
          pageIndex:'2',
        },
        {
          value:'3',
          img:require('@/assets/image/business/business2.png'),
          desc:'以需求方的业务需求为基础，进行信息系统需求分析和系统设计，通过结构化综合分布系统、计算机网络技术和软件技术，将各种分离的设备、功能和信息集成为相互联系、统一协调的系统，并为信息系统的正常运行提供支持的服务；包括信息系统设计、集成设施、运营和维护服务。',
          title:'信息系统集成与服务',
          sty:'0.6s',
          pageIndex:'3',
        },
        {
          value:'4',
          img:require('@/assets/image/business/business7.png'),
          desc:'通过无人值守智能巡检系统，在云端可对机场进行任务规划和设备管理，无人机根据任务计划自动起飞作业，并将作业成果自动上传归档，实现真正的无人化值守...',
          title:'无人机系统集成',
          sty:'1.6s',
          pageIndex:'4',
        },
        {
          img:require('@/assets/image/business/business4.png'),
          desc:'基于人工智能、大数据分析等技术，利用雷达影像、多光谱/高光谱影像、无人机航摄影像、激光点云等数据，进行地面高精度形变监测与灾害应急管理、土地利用变化...',
          title:'测绘服务',
          sty:'0.9s',
          pageIndex:'5',
        },

        {
          img:require('@/assets/image/business/business5.png'),
          desc:'我们具有民航总局《民用无人机经营许可证》和民航总局AOPA无人机驾驶员培训机构资质，并拥有全套自主知识产权的无人机专业建设体系',
          title:'无人机教育',
          sty:'1.5s',
          pageIndex:'6',
        },

      ],
      active: 0,
      productLeftMenu: [
        {
          title:'智慧水利',
          value:0,
          desc:'智慧水利以水安全为基础，以水利建设和社会管理需求为引领，应用物联网、大数据、云计算、人工智能、数字孪生等新一代信息技术，着力解决感知透彻不足、算力欠缺、信息资源开发利用低、应用智能化水平差距大等问题。通过“防汛监测、水库管理、水资源管理、河湖管理”等水利业务的智慧化应用，支撑防汛精准化决策，提升水库大坝安全监测监督水平，提供取用水调配服务管理，加强河湖长制动态管理，为新阶段水利高质量发展提供有力支撑和强力驱动。',
          img:require("@/assets/image/product/homeProductOne.png")
        },
        {
          title:'智慧电力',
          value:1,
          desc:'以泛在电力物联网“云管边端”体系构建智能微网平台。端侧主要集中在充电桩、风光储以及用能、传感设备运行状态等实时数据数据的感知与采集，并通过网关、融合终端实现数据上报，最终在云端主站实现综合展现大屏、源网荷储子模块、能耗监测等功能的建设。',
          img:require("@/assets/image/product/homeProductTwo.png")
        },
        {
          title:'环保应用',
          value:3,
          desc:'利用物联网、GIS、大数据等技术针对大气、土壤、水质等不同应用领域，对各种类型污染源监测点的数据进行收集、汇总、统计、分析、通过电脑端、手机端等方式对污染排放状况进行实时跟踪、视频监控、超标报警、历史查询和设备联动。',
          img:require("@/assets/image/product/environmental/exam4.jpg")
        },
        {
          title:'教育培训',
          value:4,
          desc:'公司具备民航总局颁发的《民用无人机经营许可证》、CAAC《民用无人机驾驶员训练机构》资质，具备多旋翼无人机、垂直起降固定翼无人机培训资质，教学人员具有CAAC（中国民用航空飞行标准司颁发的教员资质）。',
          img:require("@/assets/image/product/education/modelNew1.png")
        },

      ],
      productTitle:'',
      productVlue: 0,
      productDesc: '',
      productImg: require("@/assets/image/product/homeProductOne.png"),
      concatList:[
        {
          name:'郇东',
          phone:'15951968761',
        },
	    {
		      name:'常建翔',
		      phone:'18052661314',
	    },
        {
          name:'谢贤山',
          phone:'13770684584',
        },
        {
          name:'徐芹',
          phone:'15905166283',
        },
        {
          name:'唐鹏尧',
          phone:'13141652518',
        },

      ],
      timer:null,
      quliPicture:[],
      quliPicture1:[],
      banChangeShow: false,
      companyAddress:[118.735357,31.939596],
    }
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth
    this.bannerHeight = 600 / 1550 * this.screenWidth
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.bannerHeight = 600 / 1550 * this.screenWidth
    }
    let wow = new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animate__animated',
      offset: 0,
      mobile: false,
      live: false
    });wow.init();
    this.initMessage()
    this.setInterval()
    this.addQuliPicture()
    this.initMap();
  },
  destroyed() {
    this.clearInterval()
  },
  methods: {
    showTitle(item, index) {
      this.productTitle = item.title
      this.productVlue = index
      this.productDesc = item.desc
      this.productImg = item.img
      this.setProAnimate()
    },
    //产品与解决方案的动画
    setProAnimate() {
      let proTitleBox = document.getElementById('proTitle')
      let proDescBox = document.getElementById('proDesc')
      let proImgBox = document.getElementById('proImg')
      proTitleBox.classList.add('animate__animated','animate__fadeInDown','delay1')
      proDescBox.classList.add('animate__animated','animate__fadeInDown','delay2')
      proImgBox.classList.add('animate__animated','animate__fadeIn','delay10')
      proTitleBox.addEventListener('animationend', () => {
        proTitleBox.classList.remove('animate__animated','animate__fadeInDown','delay1')
        proDescBox.classList.remove('animate__animated','animate__fadeInDown','delay2')
        proImgBox.classList.remove('animate__animated','animate__fadeIn','delay10')
      });
    },
    //初始化banner信息
    initMessage() {
      this.bannerMessage.bannerDesc = this.bannerList[0].desc
      this.productTitle = this.productLeftMenu[0].title
      this.productDesc = this.productLeftMenu[0].desc
    },
    change(e) {
      this.movebannerIndex = e
    },
    //轮播图切换功能
    rightBtn(curIndex) {
      this.clearInterval()
      if(this.banChangeShow) {
        let rightBtn = document.getElementById('rightChange')
        rightBtn.classList.add('wow','animate__bounce')
        rightBtn.addEventListener('animationend', ()=>{
          rightBtn.classList.remove('wow','animate__bounce')
        })
      }
      let banLen = this.bannerList.length
      this.bannerMessage.direction = 'right'
      if(this.bannerMessage.banCurrentIndex < banLen - 1) {
        this.bannerMessage.banCurrentIndex += 1
      }else {
        this.bannerMessage.banCurrentIndex = 0
      }
      let index = this.bannerMessage.banCurrentIndex
      this.bannerMessage.bannerDesc = this.bannerList[index].desc
      this.movebannerIndex = index
      this.activeItem = index
      this.$refs.carousel.next()
      this.setInterval()
    },
    leftBtn() {
      this.clearInterval()
      let banLen = this.bannerList.length
      this.bannerMessage.direction = 'left'
      if(this.bannerMessage.banCurrentIndex > 0){
        this.bannerMessage.banCurrentIndex -= 1
      }else {
        this.bannerMessage.banCurrentIndex = banLen - 1
      }
      let index = this.bannerMessage.banCurrentIndex
      this.bannerMessage.bannerDesc =this.bannerList[index].desc
      this.movebannerIndex = index
      this.activeItem = index
      this.$refs.carousel.prev()
      this.setInterval()
    },
    //banner图指示点
    changeIndex(index) {
      this.clearInterval()
      this.movebannerIndex = index
      this.activeItem = index
      this.$refs.carousel.setActiveItem(index)
      this.bannerMessage.direction = 'right'
      this.bannerMessage.banCurrentIndex = index
      this.bannerMessage.bannerDesc = this.bannerList[index].desc
      // this.setInterval()
    },
    //设置定时器
    setInterval() {
      this.clearInterval()
      this.timer = setInterval(()=>{
        this.bannerMessage.direction = 'right'
        this.bannerMessage.banCurrentIndex ++
        if(this.bannerMessage.banCurrentIndex > this.bannerList.length - 1) {
          this.bannerMessage.banCurrentIndex = 0
        }
        let index = this.bannerMessage.banCurrentIndex
        this.bannerMessage.bannerDesc = this.bannerList[index].desc
      },3000)
    },
    //清除定时器
    clearInterval() {
      clearInterval(this.timer)
    },
    bannerOver() {
      this.clearInterval()
      this.banChangeShow = true
    },
    bannerLeave() {
      this.setInterval()
      this.banChangeShow = false
    },
    //动态添加资质路径
    addQuliPicture() {
      let a = 0
      let b = 5
      for (let i = 1;i<7;i++) {
        this.quliPicture1.push({
          img:require('../../assets/image/qualification/zs_' + i + '.png'),
          duration:a++ + 's'
        })
      }
      for (let i = 7;i<13;i++) {
        this.quliPicture.push({
          img:require('../../assets/image/qualification/zs_' + i + '.png'),
          duration:b++ + 's'
        })
      }
    },
    //初始化地图
    initMap() {
      AMapLoader.load({
        key:'2f107f203c9dd094148d43ac3e597451',
        version:'2.0',
        plugins:[''],
      }).then((AMap)=>{
        this.map = new window.AMap.Map("mapContainer", {
          videoMode:'2D',
          zoom:17,
          center:[118.74153000,31.93752000],
        });
        let marker = new AMap.Marker({
          position: new AMap.LngLat(118.74133000,31.93732000),
          title: '南京木牛数能信息技术有限公司',
          image:'/image/home_nr_icon_diBiao.png',
          imageSize:'20px',
        });
        // 将创建的点标记添加到已有的地图实例：
        this.map.add(marker);
      }).catch(e=>{
        console.log(e)
      })

    },

    toShow(item) {
      this.$router.push({
        name:'business',
        params:{
          currentPage:item.pageIndex + ''
        }
      })
    },
    toAboutDetail() {
      this.$router.push('aboutUs')
    }
  }
}
</script>

<style scoped lang="scss">
  .homeNew {
    font-family: Source Han Sans CN;
    .clearFloat{
      clear: both;
    }
    .transitionBanner {
      transform: translate(0 ,1200px);
    }
    .element::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
    ::v-deep .amap-logo {
      display: none;
      opacity: 0 !important;
    }
    ::v-deep .amap-copyright {
      opacity: 0;
    }
    .bannerBox{
      width: 100%;
      min-width: 1200px;
      position: relative;
      overflow-x: clip;
      .bannerCourser{
        width: 100%;
        .bannerImages{
          width: 100%;
        }
        .banContent {
          position: absolute;
          top: 30%;
          width: 100%;
          z-index: 3;
          color: #fff;
          text-align: center;
          .banTitle {
            font-size: 32px;
          }
          .subTitle {
            font-size: 20px;
            margin-top: 40px;
	        padding: 0 50px;
          }
        }
        .subTitleBanner {
          width: 100%;
          z-index: 3;
          color: #fff;
          text-align: center;

        }
        .bannerChangeTwo {
          z-index: 99;
          .leftChange {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50px;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0.3);
            text-align: center;
            line-height: 50px;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
            }
          }
          .rightChange {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            right: 50px;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0.3);
            text-align: center;
            line-height: 50px;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .changeBanner{
        width: 1200px;
        height: 80px;
        line-height: 80px;
        border-radius: 4px;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: -40px;
        z-index: 3;
        background: #fff;
        box-shadow: 3px 3px 5px #adabab61;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        .changeArrow{
          width: 50px;
          float: left;
          line-height: 80px;
          .leftArrow{
            cursor: pointer;
            .leftBtnSty{
              width: 20px;
              height: 20px;
            }
          }
        }
        .discribe{
          width: calc(100% - 50px - 15%);
          ul {
            height: 100%;
            overflow: hidden;
            margin-top: -2px;
            li {
              list-style: none;
            }
          }
        }
        .changeDot{
          float: right;
          width: 15%;
          text-align: right;
          .dotBox{
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: rgba(204, 204, 204, 1);
            display: inline-block;
            margin: 0 6px;
          }
          .activeDotBox {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #009CFF;
            display: inline-block;
            margin: 0 6px;
          }
        }
      }
    }
    .imgStyle {
      width: 100%;
    }
    .imgStylebanner {
      width: 100%;
      height: 100%;
    }
    .bussinessInside{
      width: 1200px;
      margin: auto;
      .bussinessTitle{
        font-size: 28px;
        font-weight: 600;
        color: rgba(51, 51, 51, 1);
        .bussinessSubTitle{
          font-size: 16px;
          font-weight: normal;
          margin-left: 10px;
          color: rgba(153, 153, 153, 1);
        }
      }
      .bussinessContainer{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 50px;
        .bussinessOne{
          width: 31%;
          border: 1px solid #ccc;
          margin-bottom: 30px;
          border-radius: 5px;
          background-color: #fff;
          font-size: 16px;
          .bussiImgBox{
            width: 100%;
            height: 190px;
            overflow: hidden;
            .imgStyle{
              transition: all 1s;
            }
            .imgStyle:hover {
              transform:scale(1.2);
            }
          }
          .busOneDesc {
            border-bottom: 0.10px solid #ccc;
            //padding: 20px;
	          padding:10px 30px;
            .bussunessTitleOne {
              font-size: 18px;
              font-weight: 600;
              font-family: Source Han Sans CN;
            }
            .businessDes {
              font-size: 16px;
              color: #999999;
              margin-top: 20px;
              line-height: 30px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              line-clamp: 3;
              -webkit-box-orient: vertical;
            }

          }
          .deBtn{
            height: 70px;
            text-align: right;
            line-height: 70px;
            padding: 0 20px;
            .detailsButton{
              background: transparent;
              color: #999999;
            }
            .detailsButton:hover {
              background: #009CFF;
              color: #fff;
              transition: 0.5s;
              border-radius: 25px;
            }
          }
        }
        .bussinessOne:hover {
          box-shadow: 10px 10px 10px #ccc;
          transition: 0.5s;
          transform: translate(0, -0.50px)
        }
      }
    }
    .bussinessArea{
      margin-top: 100px;
      width: 100%;
    }
    .productsSolutions{
      width: 100%;
      background: #fff;
      margin-top: 50px;
      padding: 40px 0;
      .productsContainer {
        width: 100%;
        margin-top: 50px;
        display: flex;
        justify-content: left;
        flex-wrap: nowrap;
        .leftMenu{
          width: 200px;
          border-right: 1px solid #EEEEEE;
          ul{
            width: 100%;
            list-style: none;
            font-size: 0;
            transition-duration: 0ms;
            position: relative;
          }
          li{
            width: 100%;
            height: 70px;
            padding: 10px;
            cursor: pointer;
            font-size: 18px;
            color: #666666;
            line-height: 50px;
          }
          .liActive {
            border-right: 5px solid #009CFF;
            color: #009CFF;
          }
          //下划线跟随鼠标低配版
          .hoverLi {
            width: 100%;
            height: 100%;
            //display: flex;
            //align-items: center;
            color: #999;
            position: relative;
            text-align: center;
          }
          .hoverLi:after {
            content: "";
            position: absolute;
            width: 5px;
            transform: scaleY(0);
            height: 100%;
            bottom: 0;
            right: 0;
            color: #0087ca;
            background-color: #0087ca;
            transform-origin: top right;
            transition: transform 0.4s ease-out;
          }
          .hoverLi:hover:after {
            transform: scaleX(1);
          }
          .hoverli {
            height: 100%;
            /*display: flex;
            align-items: center;*/
            color: #0087ca;
            position: relative;
            margin-top: 5px /* 10/10 */;
            border-right: 5px solid #0087ca;
            text-align: center;
          }
        }
        .rightContent {
          padding: 0 40px;
          width: calc(100% - 210px);
          .rightTitle {
            font-size: 18px;
            font-weight: 600;
          }
          .desc {
            font-size: 16px;
            color: #666666;
            margin-top: 5px;
            padding: 10px 0;
            border-bottom: 1px solid #EEEEEE;
	          line-height: 25px;
          }
          .rightImg {
            margin: 20px 0px 0px 0px;
            width: 100%;
	          //background-color: black;
	          padding: 0;
          }
        }
      }
    }
    .aboutUsBox {
      width: 100%;
      min-width: 1200px;
      min-height: 584px;
      margin-top: 80px;
      .concat {
        width: 100%;
        margin-top: 300px;
        background: url("@/assets/image/home_black_kuang.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        .container {
          width: 1200px;
          padding: 60px 0;
          margin: auto;
          .profile {
            width: 1200px;
            position: absolute;
            top: -35%;
            z-index: 1;
            background: #fff;
            box-shadow: 0px 2px 20px #adabab61;
            padding: 40px;
            .leftImg {
              width: 35%;
              margin: 10px 20px 20px 0;
              float: left;
            }
            .rightDesc {
              width: 100%;
              padding: 0 40px 0 40px;
              //float: right;

              .deBtn2{
                height: 70px;
                text-align: right;
                line-height: 70px;
                margin-top: 20px;
                .detailsButton{
                  background: transparent;
                  color: #999999;
                }
                .detailsButton:hover {
                  background: #009CFF;
                  color: #fff;
                  transition: 0.5s;
                  border-radius: 25px;
                }
              }

              .squireBox {
                width: 32px;
                height: 5px;
                background: #009CFF;
                margin-bottom: 15px;
              }

              .proTitle {
                font-size: 20px;
                font-weight: 600;
              }
              .proSubTitle {
                font-size: 16px;
                font-weight: normal;
                margin-top: 12px;
                color: #999999;
              }
              .proRightDesc {
                margin-top: 30px;
                font-size: 16px;
                color: #666666;
                line-height: 30px;
                text-indent: 20px;
              }
            }
          }
          .businessContact {
            width: 100%;
            margin-top: 360px;
            color: #fff;
            .containerTitle {
              text-align: center;
              color: #fff;
              font-size: 18px;
              .concatIcon {
                color: #009CFF;
                font-size: 16px;
                font-weight: 600;
              }
            }
            .concatList {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-top: 40px;
              .concatOne {
                width: 18%;
                height: 150px;
                border: 1px solid #fff;
                padding: 20px;
                .concatTrans {
                  line-height: 42px;
                  .classesImg {
                    width: 42px;
                    height: 42px;
                    float: left;
                    margin-right: 12px;
                  }
                }

                .transition {
                  width: 60%;
                  height: 1px;
                  background: linear-gradient(to right, #fff 40%,#000);
                  margin: 20px 0;
                }
                .oneIcon {
                  margin-right: 12px;
                }
              }
            }
          }
        }
      }
      .qualification {
        margin-top: 14px;
        .quliContent {
          width: 1200px;
          margin: auto;
          .qualificationTitle {
            text-align: center;
            color: #000;
            margin: 40px 0;
            font-size: 18px;
            font-weight: 600;
            .qualiIcon {
              color: #009CFF;
              font-size: 16px;
              font-weight: 600;
            }
          }
          .qulificationList2 {
            width:100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 30px;
            .qulificationOne {
              width: 15%;
              margin-bottom: 50px;
            }
          }
          .qulificationList1 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            border-bottom: 1px solid #ccc;
            .qulificationOne {
              width: 15%;
              margin-bottom:30px;
            }
          }
        }
      }
    }
    .contactBox {
      margin-top: 20px;
      width: 100%;
      background: #fff;
      padding-bottom: 100px;
      .contactContainer {
        width: 1200px;
        padding: 40px 0;
        .leftPhone {
          width:60%;
          padding: 0  0 0 50px;
          margin-top: 76px;
          .phoneTitle {
            color: #666666;
            font-size: 18px;
            font-weight: 600;
          }
          .phoneDetail {
            margin-top: 35px;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            line-height: 30px;
            color: #999999;
            .leftAdd {
              width: 50%;
              text-align: left;
            }
            .rightTele {
              width: 50%;
              text-align: left;
            }
          }
          .attentionUs {
            margin-top: 50px;
            .attenTitle {
              font-size: 18px;
              color: #666666;
              float: left;
            }
            .attenImgOne {
              width: 26px;
              height: 26px;
              margin: 0 10px;
            }
            .attenImg {
            }
          }

        }
        .rightMap {
          width: 40%;
          height: 310px;
          .mapContainer {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

</style>
