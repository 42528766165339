<template>
  <div class="backTop">
    <div class="backBox">
      <div class="backtopBtn" @click="backTop" v-if="isShow">
        <div>
          <img src="@/assets/image/fc_icon_dingbu_0.png" alt="" class="iconStyle">
        </div>
        返回顶部
      </div>
      <div class="backtopBtn" @mouseover="showConcat('concat')" @mouseleave="closeConcat('concat')">
        <div>
          <img src="@/assets/image/fc_icon_guanzhu_0.png" alt="" class="iconStyle">
        </div>
        联系我们

      </div>
      <div class="backtopBtn" @mouseover="showConcat('consult')"  @mouseleave="closeConcat('consult')">
        <div>
          <img src="@/assets/image/fc_icon_zixun_0.png" alt="" class="iconStyle">
        </div>
        在线咨询
      </div>
      <!--联系我们-->
      <div class="concatLeft" v-if="isConcat">
        <div class="weChatBox">
          <div class="chatImg">
            <img src="@/assets/image/backTop/weixin.png" alt="" class="imgStyle">
          </div>
          <div class="chatTitle">
            <div class="mainTitle">关注微信公众号</div>
            <div class="chatSubTitle">获取最新分享内容</div>
          </div>
          <div class="clearFloat"></div>
        </div>
        <div class="code">
          <img src="@/assets/image/backTop/home_erweima.png" alt="" class="codeImg">
        </div>
        <div class="focusBox">
          <div>微信扫一扫关注</div>
          <div>或搜索关注：Moonew</div>
        </div>
      </div>
      <!--在线咨询-->
      <div class="consultBox"  v-if="isConsult">
        <div class="title">欢迎联系木牛领域负责人</div>
        <div class="contactMan">
          <div class="contactUp">
            <div class="contactOne">
              <div class="rightContact">
                <div class="phone">
                  <div>
                    <i class="el-icon-s-custom oneIcon"></i>
                    {{ concatList[0].name }}
                  </div>
                  <div class="oneGap">
                    <i class="el-icon-phone oneIcon"></i>
                    {{ concatList[0].phone }}
                  </div>
                </div>
              </div>
            </div>
	          <div class="contactOne">
		          <div class="rightContact">
			          <div class="phone">
				          <div>
					          <i class="el-icon-s-custom oneIcon"></i>
					          {{ concatList[1].name }}
				          </div>
				          <div class="oneGap">
					          <i class="el-icon-phone oneIcon"></i>
					          {{ concatList[1].phone }}
				          </div>
			          </div>
		          </div>
	          </div>
	          <div class="contactOne">
		          <div class="rightContact">
			          <div class="phone">
				          <div>
					          <i class="el-icon-s-custom oneIcon"></i>
					          {{ concatList[2].name }}
				          </div>
				          <div class="oneGap">
					          <i class="el-icon-phone oneIcon"></i>
					          {{ concatList[2].phone }}
				          </div>
			          </div>
		          </div>
	          </div>
          </div>
          <div class="contactDown">
            <div class="contactOne">
              <div class="rightContact">
                <div class="phone">
                  <div>
                    <i class="el-icon-s-custom oneIcon"></i>
                    {{ concatList[3].name }}
                  </div>
                  <div class="oneGap">
                    <i class="el-icon-phone oneIcon"></i>
                    {{ concatList[3].phone }}
                  </div>
                </div>
              </div>
            </div>
	          <div class="contactOne">
		          <div class="rightContact">
			          <div class="phone">
				          <div>
					          <i class="el-icon-s-custom oneIcon"></i>
					          {{ concatList[4].name }}
				          </div>
				          <div class="oneGap">
					          <i class="el-icon-phone oneIcon"></i>
					          {{ concatList[4].phone }}
				          </div>
			          </div>
		          </div>
	          </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'BackTop',
  data() {
    return {
      scrollTop:'',
      isShow: false,
      isConcat: false,
      isConsult: false,
      concatList:[
	      {
		      name:'郇东',
		      phone:'15951968761',
	      },
	      {
		      name:'常建翔',
		      phone:'18052661314',
	      },
	      {
		      name:'谢贤山',
		      phone:'13770684584',
	      },
	      {
		      name:'徐芹',
		      phone:'15905166283',
	      },
	      {
		      name:'唐鹏尧',
		      phone:'13141652518',
	      },
      ],
    }
  },
  mounted() {
    document.addEventListener('scroll',this.getScrollTop)
  },
  methods: {
    getScrollTop() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop
      if(scrollTop > 650) {
        this.isShow = true
      }else {
        this.isShow = false
      }
    },
    //返回顶部
    backTop() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    showConcat(menu) {
      if(menu == 'concat') {
        this.isConcat = true
      }else if(menu == 'consult') {
        this.isConsult = true
      }
    },
    closeConcat(menu) {
      if(menu == 'concat') {
        this.isConcat = false
      }else if(menu == 'consult') {
        this.isConsult = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .backTop {
    width: 9.2rem;
    position: fixed;
    right: 1rem;
    bottom: 8rem;
    z-index: 5;

	  .oneGap {
		  margin-top: 10px;
	  }
    .imgStyle {
      width: 100%;
      height: 100%;
    }
    .clearFloat {
      clear: bottom;
    }
    .iconStyle {
      width: 4rem;
      height: 4rem;
      background: #cccccc;
    }
    .iconStyle:hover {
      background: #2B71FC;
    }
    .backBox {
      position: relative;
      .backtopBtn {
        width: 9.2rem;
        height: 9.2rem;
        padding: 1.6rem;
        background-color: #fff;
        text-align: center;
        margin-bottom: 0.5rem;
        color: #999999;
        cursor: pointer;
      }
      .concatLeft {
        position: absolute;
        left: -25rem;
        bottom: 0;
        width: 24rem;
        padding: 2rem;
        box-shadow: 3px 3px 5px 1px rgba(204, 204, 204, 0.6);
        background-color: #fff;
        z-index: 10;
        .weChatBox {
          width: 100%;
          height: 3.8rem;
          .chatTitle {
            width: 80%;
            float: right;
            text-align: center;
          }
          .chatSubTitle {
            color: #666666;
          }
        }
        .chatImg {
          width: 3.8rem;
          height: 3.8rem;
          float: left;
        }
        .mainTitle {
          font-size: 1.8rem;
          font-weight: 600;
          color: #666666;
        }
        .code {
          margin-top: 1.5rem;
          text-align: center;
          .codeImg {
            width: 15.2rem;
            height: 15.2rem;
          }
        }
        .focusBox {
          margin-top: 1rem;
          text-align: center;
          color: #999999;
          font-size: 1.6rem;
        }
      }
      .consultBox {
        position: absolute;
        left: -51rem;
        bottom: 0;
        width: 50rem;
        padding: 2rem 3rem;
        box-shadow: 3px 3px 5px 1px rgba(204, 204, 204, 0.6);
        background-color: #fff;
        z-index: 6;
        .title {
          color: #666666;
          font-weight: 600;
          font-size: 1.6rem;
        }
        .contactMan {
          margin-top: 3rem;
          width: 100%;
          .contactUp {
            display: flex;
            justify-content: space-between;
            padding-bottom: 3rem;
            border-bottom: 0.1rem solid #e7e7e7;
          }
          .contactDown {
            margin-top: 3rem;
            display: flex;
            justify-content: start;
          }
          .contactOne {
            width: 33%;
            .contactImg {
              width: 2.6rem;
              float: left;
              margin-right: 0.8rem;
              img {
                width: 100%;
                height: 2.6rem;
              }
            }
            .rightContact {
              width: calc(100% - 3.4rem);
              float: right;
              .phone {
                margin-top: 2rem;
                color: #777777;
                line-height: 2rem;
              }

            }
          }
        }
      }
    }
  }
</style>
